<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Sub Almacen" :rules="rules" :headers="headers" :inputs="inputs" />
</template>
<script>
import { InventorySubWarehouse } from '../../../models/inventarios/InventorySubWarehouse';
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import Catalog from "../../../components/templates/BasicCatalog.vue";
import Session from '../../../mixins/sessionMixin';
export default {
        data() {
        return {
        inventorywarehouses: [],  
        entity: new InventorySubWarehouse(),
        inputs: [
        new CatalogInput("Nombre", "name", "text", {cols: 6}),
        new CatalogInput("Descripcion", "key_name", "text", {cols: 4}),
        new CatalogInput("Almacen", "id_inventory_warehouse", "dropdown", { options: [], options_value: 'id', options_label: 'name', cols: 4}),
        ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "id_inventory_warehouse"})
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Descripcion", "key_name"),
        new HeaderGrid("Inventario", "strinventario")
      ],
      validate: new ValidateForm()
        };
    },
    mixins: [Session],
    components: { Catalog },
      methods: {
    newEntity() {
      this.entity = new InventorySubWarehouse(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InventorySubWarehouse(this.session); 
    console.log(JSON.stringify('subalmacen:' + this.entity));
    this.inventorywarehouses = await new InventoryWarehouse(this.session).all();
    this.inputs[2].options = this.inventorywarehouses;
  }
};
</script> 
 
<style scoped lang="scss">
</style > 
  